<template>
  <div>
    <div class="sidebar">
      <div class="head_title">
        <router-link to="/">
          <div class="title">
            Saubhagya <br />
            Rai
          </div>
        </router-link>
        <div class="sub_title">Senior Software Engineer</div>
      </div>
      <ul class="side_menu">
        <li>
          <!-- <router-link to="/">Home</router-link> | -->
          <router-link
            @click="activeClass"
            to="/about"
            :class="{ active: isActive }"
          >
            <span>0. </span>About Me</router-link
          >
        </li>
        <li>
          <router-link
            @click="activeClass"
            :class="{ active: isActive }"
            to="/projects"
            ><span>1. </span>Projects</router-link
          >
        </li>
        <li>
          <router-link
            @click="activeClass"
            :class="{ active: isActive }"
            to="/experience"
          >
            <span>2. </span>Experience</router-link
          >
        </li>
        <li>
          <router-link
            @click="activeClass"
            :class="{ active: isActive }"
            to="/skills"
            ><span>3. </span> Skills</router-link
          >
        </li>
        <!-- <li>
                    <router-link @click="activeClass" :class="{ active: isActive }" to=""><span>4. </span>Blog</router-link>
                </li> -->
        <li>
          <router-link
            @click="activeClass"
            :class="{ active: isActive }"
            to="/contact"
            ><span>4. </span>Contact Me</router-link
          >
        </li>
      </ul>
      <ul class="footer_link">
        <li>
          <a href="https://github.com/saubhagyarai" target="_blank"
            ><i class="fa-brands fa-github"></i
          ></a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/saubhagyarai/" target="_blank"
            ><i class="fa-brands fa-linkedin"></i
          ></a>
        </li>

        <li>
          <a href="https://www.instagram.com/saubhagyatakkarai/" target="_blank"
            ><i class="fa-brands fa-instagram"></i
          ></a>
        </li>
        <li>
          <a href="https://twitter.com/saubhagyarai"
            ><i class="fa-brands fa-twitter"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  //   data() {
  //     return {
  //       isActive: false,
  //     };
  //   },
  //   methods: {
  //     activeClass: function () {
  //       //   this.isActive = !this.isActive;
  //       this.isActive = true;
  //     },
  //   },
};
</script>
<style>
</style>