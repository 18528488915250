<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/> -->
  <div class="main_container">
    <Sidebar />

    <router-view />
  </div>
</template>

<style>
@import url("");
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"); */
@import url("./assets/bootstrap/css/bootstrap.min.css");
@import url("https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css");
@import url("./assets/css/main.css");
</style>

<script>
document.title = "Saubhagya Rai";

import Sidebar from "./components/SideBar.vue";
document.title = "Saubhagya Rai";

export default {
  components: {
    Sidebar,
  },
};
</script>